import { Col, Container, Row, Image } from 'react-bootstrap';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function VectorImage() {
  return (
    <Container className="my-4 mt-5">
      <Row className='mt-4'>
        {/* Left side: Image */}
        <Col xs={12} md={6}  className="d-flex justify-content-center align-items-center">
          <Image
            src="/images/vector.jpg" // Replace with the path to your image
            alt="Descriptive Alt Text"
            className="img-fluid" // Ensures the image is responsive
            style={{ maxHeight: '400px', width: 'auto' }} // Adjust maxHeight as needed
          />
        </Col>

        {/* Right side: Text */}
        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f8f8ff' }}>
          <div className="text-container text-center p-3">
            <h1 style={{ fontFamily: 'math-italic' }}>
              <strong style={{ color: '#9acd32' }}>Ocupiie </strong>
              <strong>Hospitality Academy</strong>
            </h1>
            <h6>
              <strong>Where Excellence Meets Opportunity</strong>
            </h6>
            <h5 style={{ fontFamily: 'math-italic', color: '#ffad00' }}>
              <strong style={{ color: '#080809' }}>Join Us</strong> today and take the first step towards an exciting career in hospitality!
            </h5>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default VectorImage;
