import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Slide from './Slider/Slide'
import Buttons from './Button/Buttons';
import Hospitality from './Hospitality/Hospitality';
import CoursesHome from './CoursesHome/CoursesHome';
import MissionVision from './MissionAndVision/MisionVision';
import WhyChoose from './WhyChooseUs/WhyChoose';
import VectorImage from './VectorImage/VectorImage';
import Onlinelearn from './OnlineLearn/Onlinelearn';

function Home() {
  return (
    <>

      <div>
        <Slide />
      </div>
      <div>
        <Buttons />
      </div>
      <div>
        <VectorImage />
      </div>
     
      <div >
        <Hospitality />
      </div>
      <div>
        <Onlinelearn/>
      </div>
      <div >
        <CoursesHome />
      </div>
      <div>
        <MissionVision/>
      </div>
      <div>
        <WhyChoose/>
      </div>
      
    </>
  )
}

export default Home