import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button
import BusinessEnquiryForm from '../EnquiryForm/BusinessEnquiryForm'; // Ensure correct path
import './Business.css'; // Separate CSS for Corporate Links if needed

const Business = () => {
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    const handleShow = () => setShowModal(true); // Function to show the modal
    const handleClose = () => setShowModal(false); // Function to close the modal

    return (
        <div className="corporate-links">
            <h5>Corporate Links</h5>  
                <a href="/franchise" className="corporate-link">Franchise</a><br/>
                <Button variant="primary" onClick={handleShow} className="corporate-link">Business Enquiry</Button>
       

            {/* Modal for Business Enquiry Form */}
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static" // Prevent closing the modal on backdrop click
                keyboard={false} // Prevent closing the modal with keyboard Esc key
                centered
                className="custom-modal" // Custom class for additional styling
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body style={{ padding: '0', margin: '0' }}> {/* Remove padding and margin */}
                    <BusinessEnquiryForm />
                </Modal.Body>
                <Modal.Footer style={{ margin: '5px' }}>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Business;
