import { Col, Container, Row, Image, Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Onlinelearn.css";
import { Link } from "react-router-dom";

function Onlinelearn() {
  return (
    <Container fluid className="containeronliness p-0">
      <Row className="m-0 p-0">
        <Col className="m-0 p-0" style={{ position: "relative" }}>
          {/* The Image */}
          <Image
            className="d-block img-fluid onlineimage"
            src="images/learnonlinell.png"
            alt="beautiful-young-hispanic-salesperson"
            
          />
          
          {/* The Heading positioned on top of the image */}
          <h1 className="h1online"><strong style={{fontFamily: 'math-italic',color:'#9acd32'}}>Ocupiie</strong> Online <strong style={{fontFamily: 'math-italic',color:'#ffad00'}}>Languages</strong></h1>

          {/* The Button positioned on the right side of the image */}
          <Link to="/onlinelanguages">
          <Button
            className="buttonright"
            variant="primary"
            href="#get-started" >
            Get More Information
          </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Onlinelearn;
