import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LanguageOnline.css";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import EnquiryFormLanguage from "../EnquiryForm/EnquiryFormLanguage";

function LanguageOnline() {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <Container fluid={true} className="p-0 m-0">
      {/* learn language with logo on top */}
      <Row className="position-relative p-0 m-0 ">
        <Col className="p-0">
          <div className="image-container" style={{ position: "relative" }}>
            {/* Company logo overlay on image */}
            <Link to="/">
              <Image
                src="images/ocupiielogos.png"
                alt="OCUPIIE Logo"
                className="img-fluid"
                style={{
                  position: "absolute",
                  top: "-30px", // Adjust the vertical position
                  left: "20px", // Adjust the horizontal position
                  maxWidth: "150px", // Adjust the size of the logo
                  zIndex: "10", // Ensures logo is above the image
                }}
              />
            </Link>

            {/* Background Image */}
            <Image
              className="d-block w-100 img-fluid frenchaisies"
              src="images/learn_lang_online.png"
              alt="First slide"
            />

            {/* Button on the right side */}

            <Button
              className="enquirenow"
              onClick={handleShow}
              style={{
                position: "fixed",
                top: "180px",
                right: "50px",
                zIndex: "10",
                color: "white",
                backgroundColor: "#9acd32", // Match your logo color
                border: "none",
                fontSize: "30px",
                fontWeight: "bold",
                height: "50px", // Height of the button
                width: "190px", // Width of the button
                transform: "rotate(90deg)", // Rotate the button
                transformOrigin: "bottom right", // Set the origin of the rotation to the bottom right corner
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "0px", // Optional: adjust padding as needed
              }}
            >
              Enquiry Now
            </Button>
          </div>
        </Col>
      </Row>

      {/* language button */}
      <Row className="p-0 m-0 justify-content-center mt-3">
        <Col md={2}>
          <Image
            src="images/englishonliness.png"
            className="onlinelanguagepng"
          />
          <p>
            <Link to="/courses/english" className="language-linkss">
              English
            </Link>
          </p>
        </Col>
        <Col md={2} >
          <Image src="images/frenchonline.png" className="onlinelanguagepng" />
          <p>
            <Link to="/courses/french" className="language-linkss">
              French
            </Link>
          </p>
        </Col>
        <Col md={2} >
          <Image src="images/germanonline.png" className="onlinelanguagepng" />
          <p>
            <Link to="/courses/german" className="language-linkss">
              German
            </Link>
          </p>
        </Col>
        <Col md={2} >
          <Image src="images/arabiconline.png" className="onlinelanguagepng" />
          <p>
            <Link to="/courses/arabic" className="language-linkss">
              Arabic
            </Link>
          </p>
        </Col>
        <Col md={2}>
          <Image src="images/spanishonline.png" className="onlinelanguagepng" />
          <p>
            <Link to="/courses/spanish" className="language-linkss">
              Spanish
            </Link>
          </p>
        </Col>
      </Row>

      {/* join our */}
      <Row className="position-relative p-0 m-0 mt-3">
        <Col className="fit-content-card p-0">
          <Image
            className="d-block"
            style={{ width: "480px", height: "500px" }}
            src="images/onlinelearnings.png"
            alt="First slide"
          />
        </Col>
        <Col className="fit-content-card mt-5 pt-5">
          <p>
            <strong
              style={{
                fontFamily: "times new roman",
                fontSize: "60px",
                color: "#ffad00",
              }}
            >
              Join
            </strong>
          </p>
          <p>
            <strong style={{ fontFamily: "times new roman", fontSize: "35px" }}>
              {" "}
              our{" "}
            </strong>
            <strong
              style={{
                fontSize: "50px",
                fontFamily: "Baguet Script",
                color: "#9acd32",
              }}
            >
              Online Language Training
            </strong>{" "}
          </p>
          <p>
            {" "}
            <strong style={{ fontFamily: "times new roman", fontSize: "35px" }}>
              today and take a step toward global opportunities with enhanced
              language skills.
            </strong>
          </p>
        </Col>
      </Row>

      {/* course details */}
      <Row className="p-0 m-0 justify-content-center mt-3">
        <Col md={3} className="p-0 m-0">
          <Card className="p-0 m-0 cardcontainer">
            <Card.Img
              variant="top"
              src="images/engsss.jpg"
              className="CardImmm"
            />
            <Card.Body className="p-0 m-0 cardbodycolor">
              <Card.Title className="p-0 m-0 namelanguage">English</Card.Title>
              <Card.Text className="p-0 m-0 mx-2" style={{ fontSize: "20px" }}>
                <p>
                  Duration:<strong>12 weeks 3 days per week</strong>
                  <br />
                  <strong>Course Fees</strong>
                  <br />
                  Single:-<strong>18,720 INR</strong> <br />
                  Group 5 person:-<strong>9,000 INR </strong>
                </p>
              </Card.Text>
              <Button  className="px-1 m-0 buttoncourse" variant="primary">
                Get the Course
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="p-0 m-0">
          <Card className="p-0 m-0 cardcontainer">
            <Card.Img
              variant="top"
              src="images/franceonlines.jpg"
              className="CardImmm"
            />
            <Card.Body className="p-0 m-0 cardbodycolor">
              <Card.Title className="p-0 m-0 namelanguage">French</Card.Title>
              <Card.Text className="p-0 m-0 mx-2" style={{ fontSize: "20px" }}>
                <p>
                  Duration:<strong>12 weeks 3 days per week</strong> <br />
                  <strong>Course Fees</strong>
                  <br />
                  Single:-<strong>21,060 INR</strong> <br />
                  Group 5 person:-<strong>10,530 INR</strong>
                </p>
              </Card.Text>
              <Button className="px-1 m-0 buttoncourse" variant="primary">
                Get the Course
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="p-0 m-0">
          <Card className="p-0 m-0 cardcontainer">
            <Card.Img
              variant="top"
              src="images/germanonlines.jpg"
              className="CardImmm"
            />
            <Card.Body className="p-0 m-0 cardbodycolor">
              <Card.Title className="p-0 m-0 namelanguage">German</Card.Title>
              <Card.Text className="p-0 m-0 mx-2" style={{ fontSize: "20px" }}>
                <p>
                  Duration:<strong>8 weeks 3 days per week</strong> <br />
                  <strong>Course Fees</strong>
                  <br />
                  Single:-<strong>14,040 INR</strong> <br />
                  Group 5 person:-<strong>7,020 INR</strong>
                </p>
              </Card.Text>
              <Button className="px-1 m-0 buttoncourse" variant="primary">
                Get the Course
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="p-0 m-0 justify-content-center p-0 m-0 mt-3">
        <Col md={3} className="p-0 m-0">
          <Card className="p-0 m-0 cardcontainer">
            <Card.Img
              variant="top"
              src="images/spanishonlines.jpg"
              className="CardImmm"
            />
            <Card.Body className="p-0 m-0 cardbodycolor">
              <Card.Title className="p-0 m-0 namelanguage">Spanish</Card.Title>
              <Card.Text className="p-0 m-0 mx-2" style={{ fontSize: "20px" }}>
                <p>
                  Duration:<strong>8 weeks 3 days per week</strong>
                  <br />
                  <strong>Course Fees</strong>
                  <br />
                  Single:-<strong>14,040 INR</strong> <br />
                  Group 5 person:-<strong>7,020 INR</strong>
                </p>
              </Card.Text>
              <Button className="p-1 m-0 buttoncourse" variant="primary">
                Get the Course
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="p-0 m-0">
          <Card className="p-0 m-0 cardcontainer">
            <Card.Img
              variant="top"
              src="images/arabiconlines.jpg"
              className="CardImmm"
            />
            <Card.Body className="p-0 m-0 cardbodycolor">
              <Card.Title className="p-0 m-0 namelanguage">Arabic</Card.Title>
              <Card.Text className="p-0 m-0 mx-2" style={{ fontSize: "20px" }}>
                <p>
                  Duration:<strong>12 weeks 3 days per week</strong> <br />
                  <strong>Course Fees</strong>
                  <br />
                  Single:-<strong>18,720 INR</strong> <br />
                  Group 5 person:-<strong>9,000 INR</strong>
                </p>
              </Card.Text>
              <Button className="px-1 m-0 buttoncourse" variant="primary">
                Get the Course
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row
        className="position-relative justify-content-center p-0 m-0" >
        <Col className="px-3 pt-4   mb-4 classcolomnborder">
          <h3>
            We Offer comprehensive online language training designed to help you
            master new languages from the comfort of your home. Our courses are
            tailored for hospitality professionals and anyone looking to enhance
            their global communication skills
          </h3>
          {/* Your content goes here */}
        </Col>

        {/* <Col sm={2} md={5} className="p-0 m-0 text-align-left">
          <Image
            className="d-block img-fluid imagegirls"
            src="images/women.png"
            alt="beautiful-young-hispanic-salesperson"
          />
        </Col> */}
      </Row>

      {/* Modal for the enquiry form */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <EnquiryFormLanguage />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default LanguageOnline;
