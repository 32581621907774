import React, { useState } from 'react';
import { Form, Button, FloatingLabel, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Axios for HTTP requests

const EnquiryFormLanguage = () => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    Name: '',      // Name field
    Mobile: '',    // Mobile field
    Email: '',     // Email field
    State: '',     // State field
    Language: '',  // Choose Language field
    Learn: ''      // I Want to Learn field
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form default behavior (reloading the page)
    const form = event.currentTarget;

    // Validate form fields
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true); // Set form as validated

    // Prepare all the form data to send
    try {
      // Sending form data using axios (you can also use fetch)
      const response = await axios.post("https://api.ocupiiehospitalityacademy.com/api/enquiries", formData);
      
      if (response.status === 201) {
        // Handle success response
        alert('Form submitted successfully!');
        
        // Reset form fields
        setFormData({
          Name: '',
          Mobile: '',
          Email: '',
          State: '',
          Language: '',
          Learn: ''
        });
      } else {
        // Handle non-200 responses
        alert('Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error.response ? error.response.data : error.message);
      alert('Failed to submit the form. Please try again later.');
    }
  };

  return (
    <Container>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <h4> Language Enquiry Form</h4>

        {/* Name Field */}
        <Form.Group controlId="name" className="mb-1">
          <FloatingLabel label="Full Name">
            <Form.Control
              type="text"
              name="Name" // Match backend field name
              value={formData.Name}
              onChange={handleInputChange}
              placeholder="Full Name"
              maxLength={255} // Corresponds to the backend rule
              required
            />
            <Form.Control.Feedback type="invalid">Please provide your full name.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Mobile Number Field */}
        <Form.Group controlId="mobile" className="mb-1">
          <FloatingLabel label="Phone Number">
            <Form.Control
              type="tel"
              name="Mobile" // Match backend field name
              value={formData.Mobile}
              onChange={handleInputChange}
              placeholder="Phone Number"
              pattern="[0-9]{10,12}" // Digits between 10-12
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid phone number (10-12 digits).</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Email Field */}
        <Form.Group controlId="email" className="mb-1">
          <FloatingLabel label="Email Address">
            <Form.Control
              type="email"
              name="Email" // Match backend field name
              value={formData.Email}
              onChange={handleInputChange}
              placeholder="Email Address"
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* State Selection */}
        <Form.Group controlId="state" className="mb-1">
          <FloatingLabel label="Select State">
            <Form.Select
              name="State"
              value={formData.State}
              onChange={handleInputChange}
              required
            >
              <option value="">Select State</option>
              <option value="Andaman Nicobar & Iseland" >Andaman Nicobar & Iseland</option>
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="West Bengal">West Bengal</option>
              {/* Add more states as needed */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a state.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Choose Language Selection */}
        <Form.Group controlId="language" className="mb-1">
          <FloatingLabel label="Choose Language">
            <Form.Select
              name="Language"
              value={formData.Language}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Language</option>
              <option value="English">English</option>
              <option value="Arabic" >Arabic</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
              <option value="German">German</option>
              {/* Add more languages as needed */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a language.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* I Want to Learn Selection */}
        <Form.Group controlId="learn" className="mb-1">
          <FloatingLabel label="I Want to Learn">
            <Form.Select
              name="Learn"
              value={formData.Learn}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Prefrence</option>
              <option value="Basic">Singal</option>
              <option value="Advanced">Group</option>
              {/* Add more options as needed */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a course level.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Submit Button */}
        <div className="d-flex justify-content-end align-items-end">
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </Container>
  );
};

export default EnquiryFormLanguage;
