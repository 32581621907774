import { NavLink } from 'react-router-dom'; // Import NavLink for active state
import React from 'react';
import './CourseListColor.css'
import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap';

const CourseList = ({ courses, setSelectedCourse, setSelectedSection }) => {
  return (
    <div>
      <Container>
        <h3>Ocupiie Hospitality Academy</h3>
        <Row>
          <Col className="d-flex flex-column gap-3">
            <Button variant="outline-primary" className='sectionButton' onClick={() => setSelectedSection('courseOverview')}>Overview</Button>
          </Col>
          <Col className="d-flex flex-column gap-3">
            <Button variant="outline-primary" className='sectionButton' onClick={() => setSelectedSection('syllabus')}>Syllabus</Button>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col className="d-flex flex-column gap-3">
            <Button variant="outline-primary" className='sectionButton' onClick={() => setSelectedSection('sessions')}>Sessions</Button>
          </Col>
          <Col className="d-flex flex-column gap-3">
            <Button variant="outline-primary" className='sectionButton' onClick={() => setSelectedSection('duration')}>Duration</Button>
          </Col>
        </Row>
      </Container>

<ListGroup>
  {courses.map(course => (
    <ListGroup.Item key={course.id} action className='custom-list-item'>
      <NavLink
        to={`/courses/${course.id}`}
        onClick={() => {
          setSelectedCourse(course.id);
          setSelectedSection(null); // Reset section scrolling
        }}
        className={({ isActive }) => isActive ? 'active-course-link' : ''} // Apply active class
        style={{ textDecoration: 'none', color: 'inherit', display: 'block', width: '100%' }} // Make NavLink take full width
      >
        {course.name}
      </NavLink>
    </ListGroup.Item>
  ))}
</ListGroup>


    </div>
  );
};

export default CourseList;
