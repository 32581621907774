import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import CourseList from "./CourseList"; // Assuming this is your sidebar with course list
import FrenchCourseDetails from "./FrenchCourseDetails";
import GermanCourseDetails from "./GermanCourseDetails";
import SpanishCourseDetails from "./SpanishCourseDetails";
import EnglishCourseDetail from "./EnglishCourseDetail";
import ArabicCourseDetails from "./ArabicCourseDetails";
import ProfessionalGroomingandEtiquetteTrainingCourseDetails from "./ProfessionalGroomingandEtiquetteTrainingCourseDetails";
import PersonalityDevelopmentandSoftSkillsTraining from "./PersonalityDevelopmentandSoftSkillsTraining";
import ComprehensiveSoftSkillsDevelopment from "./ComprehensiveSoftSkillsDevelopment";
import EffectiveCommunicationSkillsTraining from "./EffectiveCommunicationSkillsTraining";
import FoodandBeverageManagementTraining from "./FoodandBeverageManagementTraining";
import FoodIndustryManagementandOperations from "./FoodIndustryManagementandOperations";
import MasteringHotelFinancials from "./MasteringHotelFinancials";
import RestaurantManagementTraining from "./RestaurantManagementTraining";
import HotelMarketingStrategiesTraining from "./HotelMarketingStrategiesTraining";
import KitchenOperationsTraining from "./KitchenOperationsTraining";
import FrontOfficeManagementTraining from "./FrontOfficeManagementTraining";
import RoomServiceSkillsTraining from "./RoomServiceSkillsTraining";
import EnquiryForm from "../EnquiryForm/EnquiryForm";

// Course data
const courses = [
    // { id: "english", name: "English", imageUrl: "/images/Artboard1.png" },
    { id: "french", name: "French", imageUrl: "/images/french.png" },
    { id: "german", name: "German", imageUrl: "/images/german.png" },
    { id: "spanish", name: "Spanish", imageUrl: "/images/spanish.jpg" },
    { id: "english", name: "English", imageUrl: "/images/english.png" },
    { id: "arabic", name: "Arabic", imageUrl: "/images/arabic.png" },
    { id: "groomingandetiquette", name: "Professional  Grooming and Etiquette Training", imageUrl: "/images/grooming.png" },
    { id: "personalitydevelopmentandsoftskillstraining", name: "Personality Developmentand Soft Skills Training", imageUrl: "/images/personality.png" },
    { id: "comprehensivesoftskillsdevelopment", name: "Comprehensive Soft Skills Development", imageUrl: "/images/comprehensive.jpg" },
    { id: "effectivecommunicationskillstraining", name: "Effective Communication Skills Training", imageUrl: "/images/communication.png" },
    { id: "foodandbeveragemanagementtraining", name: "Food and Beverage Management Training", imageUrl: "/images/foodandbeverage.png" },
    { id: "foodindustrymanagementandoperations", name: "Food Industry Management and Operations", imageUrl: "/images/foodindustry.png" },
    { id: "masteringhotelfinancials", name: "Mastering Hotel Financials", imageUrl: "/images/financials.png" },
    { id: "restaurantmanagementtraining", name: "Restaurant Management Training", imageUrl: "/images/restaurantmanagement.jpg" },
    { id: "hotelmarketingstrategiestraining", name: "Hotel Marketing Strategies Training", imageUrl: "/images/marketing.png" },
    { id: "kitchenoperationstraining", name: "Kitchen Operations Training", imageUrl: "/images/kitchen.png" },
    { id: "frontofficemanagementtraining", name: "Front Office Management Training", imageUrl: "/images/frontofficce.png" },
    { id: "roomserviceskillstraining", name: "Room Service Skills Training", imageUrl: "/images/room.png" },

    // Add other courses...
];

const CoursePage = () => {
    const { courseId } = useParams(); // Get course ID from URL
    const [selectedSection, setSelectedSection] = useState(null); // Control section scroll
    const courseDetailsContainerRef = useRef(null);

    // Find the selected course from the list
    const selectedCourse = courses.find((course) => course.id === courseId) || courses[0]; // Default to the first course

    // Scroll to top when the course changes
    useEffect(() => {
        if (courseDetailsContainerRef.current) {
            courseDetailsContainerRef.current.scrollTop = 0;
        }
    }, [courseId]);

    // useEffect(() => {
    //     console.log("Current course ID:", courseId);

    // }, [courseId]);

    // Render the selected course's details based on the ID from URL
    const renderCourseDetails = () => {
        switch (courseId) {
            case "french":
                return <FrenchCourseDetails selectedSection={selectedSection} />;
            case "german":
                return <GermanCourseDetails selectedSection={selectedSection} />;
            case "spanish":
                return <SpanishCourseDetails selectedSection={selectedSection} />;
            case "english":
                return <EnglishCourseDetail selectedSection={selectedSection} />;
            case "arabic":
                return <ArabicCourseDetails selectedSection={selectedSection} />;
            case "groomingandetiquette":
                return <ProfessionalGroomingandEtiquetteTrainingCourseDetails selectedSection={selectedSection} />;
            case "personalitydevelopmentandsoftskillstraining":
                return <PersonalityDevelopmentandSoftSkillsTraining selectedSection={selectedSection} />;
            case "comprehensivesoftskillsdevelopment":
                return <ComprehensiveSoftSkillsDevelopment selectedSection={selectedSection} />;
            case "effectivecommunicationskillstraining":
                return <EffectiveCommunicationSkillsTraining selectedSection={selectedSection} />;
            case "foodandbeveragemanagementtraining":
                return <FoodandBeverageManagementTraining selectedSection={selectedSection} />;
            case "foodindustrymanagementandoperations":
                return <FoodIndustryManagementandOperations selectedSection={selectedSection} />;
            case "masteringhotelfinancials":
                return <MasteringHotelFinancials selectedSection={selectedSection} />;
            case "restaurantmanagementtraining":
                return <RestaurantManagementTraining selectedSection={selectedSection} />;
            case "hotelmarketingstrategiestraining":
                return <HotelMarketingStrategiesTraining selectedSection={selectedSection} />;
            case "kitchenoperationstraining":
                return <KitchenOperationsTraining selectedSection={selectedSection} />;
            case "frontofficemanagementtraining":
                return <FrontOfficeManagementTraining selectedSection={selectedSection} />;
            case "roomserviceskillstraining":
                return <RoomServiceSkillsTraining selectedSection={selectedSection} />;

            // Add cases for other courses...
            default:
                return <p>Select a course to see details</p>;
        }
    };

    return (
        <Container fluid={true} className="p-0 m-0">
            {/* Display the image dynamically */}
            {selectedCourse && (
                <Row className="m-0 p-0">
                    <Col className="m-0 p-0">
                        <div>
                            <Image
                                src={selectedCourse.imageUrl}
                                alt={`${selectedCourse.name} Image`}
                                style={{ width: "100%", height: "auto" }}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            <Row className="m-0 p-0 mt-2">
                {/* First Column: Sidebar with Course List */}
                <Col md={3} xs={3} className="text-start m-0 p-0">
                    <CourseList className="p-0"
                        courses={courses}
                        setSelectedCourse={(courseId) => {
                            setSelectedSection(null); // Reset section scrolling
                        }}
                        setSelectedSection={setSelectedSection}
                    />
                </Col>

                {/* Second Column: Course Details */}
                <Col md={5} xs={6} className="text-start p-0 m-0" style={{ backgroundColor: '#f8f6e3', color: '#080809' }}>
                    <div

                        ref={courseDetailsContainerRef}
                        style={{ maxHeight: "1000px", overflowY: "auto", paddingLeft: '15px' }}
                    >
                        {renderCourseDetails()}
                    </div>
                </Col>
                <Col md={4}>

                    <EnquiryForm />
                </Col>
            </Row>
        </Container>
    );
};

export default CoursePage;
