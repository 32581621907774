import React,{useState} from "react";
import { Container, Image, Row, Col,Button,Modal } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Franchise.css";
import BusinessEnquiryForm from "../EnquiryForm/BusinessEnquiryForm";

function Franchise() {

    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    const handleShow = () => setShowModal(true); // Function to show the modal
    const handleClose = () => setShowModal(false); // Function to close the modal

  return (


    <Container className="containerfranchise">
      <Row className="image-container">
        {/* The Heading positioned on top of the image */}
        <Col lg={6} className="headdingdiv position-relative">
          <div className="paragraphdiv">
            <p>
              <strong style={{ color: "#ffad00" }}>START </strong>YOUR
              INVESTMENT OPPORTUNITY WITH{" "}
              <strong style={{ color: "#9acd32" }}>OCUPIIE HOSPITALITY ACADEMY</strong>
            </p>
          </div>
        </Col>
        <Col lg={6}>
          {/* The Image */}
          <Image
            src="./images/startinvestment.png"
            className="startinvestment"
            alt="Franchise"
          />
        </Col>
        <Button
              className="enquirenow"
              onClick={handleShow}
              style={{
                position: "fixed",
                top: "180px",
                right: "65px",
                zIndex: "10",
                color: "white",
                backgroundColor: "#9acd32", // Match your logo color
                border: "none",
                fontSize: "24px",
                fontWeight: "bold",
                height: "65px", // Height of the button
                width: "180px", // Width of the button
                transform: "rotate(90deg)", // Rotate the button
                transformOrigin: "bottom right", // Set the origin of the rotation to the bottom right corner
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "0px", // Optional: adjust padding as needed

              }}
            >
              Business Enquiry
            </Button>
      </Row>

      <Row>
        <Col className="bussinesscol">
          <h1>
            <span style={{fontFamily: 'didot,serif'}}>We are Happy to help you Grow Your Business</span>
            <br />
            <strong style={{ color: "#9acd32" }}>
              Ocupiie Hospitality Academy
            </strong>
          </h1>
        </Col>
      </Row>
      <Row className="p-0m-0">
        <h1><strong>Impressive <span style={{color:'#ffad00'}}>Returns</span></strong></h1>
        <Col
          className="investmentcol1 p-0 m-0"
          style={{ backgroundImage: `url('/images/lucrative.png')` }}
        >
          <p>
            <strong
              style={{
                color: "#9acd32",
                textAlign: "center",
                fontSize: "60px",
              }}
            >
              Set Up
            </strong>
            <br />{" "}
            <strong style={{ fontSize: "35px", color: "white" }}>
              {" "}
              A Lucrative Busines
            </strong>
          </p>
        </Col>
        <Col
          className="investmentcol2 p-0 m-0"
          style={{ backgroundImage: `url('/images/substantial.jpg')` }}
        >
          <p>
            {" "}
            <strong style={{ fontSize: "35px", color: "white" }}>
              Substantial Gains
            </strong>
          </p>
        </Col>
      </Row>
      <Row className="p-0 m-0 mt-3">
        <Col className="patnercol">
          <Image
            src="./images/partner.png"
            className="patner"
            alt="Franchise"
          />
        </Col>
        <Col className="patnercol">
          <p>
            <strong style={{ fontSize: "25px" }}>Join Hands with </strong>
            <strong style={{ color: "#9acd32", fontSize: "35px" }}>
              {" "}
              Ocupiie Hospitality Academy
            </strong>
            <br />{" "}
            <strong style={{ fontSize: "28px" }}>
              <span
                style={{
                  marginTop: "10px",
                  display: "flex",
                  fontFamily: "new-roman",
                }}
              >
                Start Your Own Business Today With
              </span>
              <span
                style={{
                  color: "#ffad00",
                  marginLeft: "5px",
                }}
              >
                Ocupiie
              </span>
            </strong>
          </p>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg={8} className="p-0 m-0 pt-2">
          <ul className="custom-icon-list">
            <h1>
              YOUR BUSINESS <span style={{ color: "#ffad00" }}>BENIFITS</span>
            </h1>
            <li>
              <i className="bi bi-check2-square checkboxcol1"></i>Established
              Brand Recognition
            </li>
            <li>
              {" "}
              <i className="bi bi-check2-square checkboxcol1"></i>Comprehensive
              Training & Support
            </li>
            <li>
              {" "}
              <i className="bi bi-check2-square checkboxcol1"></i>Proven
              Business Model
            </li>
            <li>
              {" "}
              <i className="bi bi-check2-square checkboxcol1"></i>Exclusive
              Territory Rights
            </li>
            <li>
              <i className="bi bi-check2-square checkboxcol1"></i>Marketing
              Assistance
            </li>
            <li>
              {" "}
              <i className="bi bi-check2-square checkboxcol1"></i>Ongoing
              Operational Support
            </li>
            <li>
              {" "}
              <i className="bi bi-check2-square checkboxcol1"></i>Access to
              Curriculum & Educational Materials
            </li>
            <li>
              {" "}
              <i className="bi bi-check2-square checkboxcol1"></i>Revenue
              Opportunities Through Multiple Streams
            </li>
            <li>
              {" "}
              <i className="bi bi-check2-square checkboxcol1"></i>Worldwide
              Student Placement Support
            </li>
          </ul>
        </Col>

        <Col lg={4} className="p-0 m-0">
          {/* The Image */}
          <Image
            src="./images/startinvestment.png"
            className="benifitimage"
            alt="Franchise"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="d-flex flex-column justify-content-center align-items-center content-center">
           <h1> <span style={{color:'#9acd32',fontFamily:'time new roman'}}>Ocupiie Hospitality Academy</span></h1>
           <p><span style={{fontSize:'27px'}}>Gives Reliable aid to its Franchise for.</span></p>
        </Col>

      </Row>
      <Row>
      <Col xs={12} md={4} lg={3} className=" justify-content-center align-items-center">
        <Image src="./images/Educationcenterandlayout.jpg" className="ocupiieimage" rounded />
        <h4><span style={{color:'#080809'}}>Center Setup & Support</span></h4>
      </Col>
      <Col xs={12} md={4} lg={3} className=" justify-content-center align-items-center">
        <Image src="./images/Recruitment.jpg" className="ocupiieimage" rounded />
        <h4><span style={{color:'#080809'}}>Staff Recruitment Helping</span></h4>
      </Col>
      <Col xs={12} md={4} lg={3} className=" justify-content-center align-items-center">
        <Image src="./images/Placement.jpg" className="ocupiieimage" rounded />
        <h4><span style={{color:'#080809'}}>Course & Placement Support</span></h4>
      </Col>
      <Col xs={12} md={4} lg={3} className=" justify-content-center align-items-center">
        <Image src="./images/Marketingandads.jpg" className="ocupiieimage" rounded />
        <h4><span style={{color:'#080809'}}>Marketing & Advertisment</span></h4>
      </Col>
    </Row>
      <Row className="mt-3 mb-3 ">
      <h1><strong>Your Set-Up <span style={{color:'#ffad00'}}>Need</span> </strong></h1>
      <Col xs={12} md={4} className="text-center">      
        <Image className="text-center-investment" src="/images/carpetarea.png" roundedCircle />
        <h4>Carpet Area</h4> {/* Title for the first image */}
      </Col>
      <Col xs={12} md={4} className="text-center">      
        <Image className="text-center-investment" src="/images/investmentpng.png" roundedCircle />
        <h4>Investment 3 to 5 Lakh</h4> {/* Title for the second image */}
      </Col>
      <Col xs={12} md={4} className="text-center">  
        <Image className="text-center-investment" src="/images/continueyourbusiness.png" roundedCircle />
        <h4>Continue Your Own Business</h4> {/* Title for the third image */}
      </Col>
    </Row>

       {/* Modal for Business Enquiry Form */}
       <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static" // Prevent closing the modal on backdrop click
                keyboard={false} // Prevent closing the modal with keyboard Esc key
                centered
                className="custom-modal" // Custom class for additional styling
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body style={{ padding: '0', margin: '0' }}> {/* Remove padding and margin */}
                    <BusinessEnquiryForm/>
                </Modal.Body>
                <Modal.Footer style={{ margin: '5px' }}>
                </Modal.Footer>
            </Modal>
    </Container>
  );
}

export default Franchise;
