import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import EnquiryForm from '../EnquiryForm/EnquiryForm'
import 'bootstrap/dist/css/bootstrap.min.css';
// import EnquiryForm from '../EnquiryForm'


function WhyChoose() {
  return (

    <Container fluid={true} >
    <Row>
        <Col md={7} style={{backgroundColor:'transparent'}}>
        <h3 style={{fontFamily:'math-italic', textAlign: 'left'}}>Why Choose <strong style={{color:'#ffad00'}}>Ocupiie Hospitality Academy </strong>?</h3>
        </Col>
    </Row>
        <Row>
            <Col sm={6} md={6} lg={8}style={{backgroundColor:'transparent'}} className='mr-4'>
            <ul style={{backgroundColor:'#9acd32',color:'#080809',paddingTop:'10px',textAlign: 'left'}}>
                <li>Industry-Ready Skills</li>
                <li>Globally Recognized Certifications</li>
                <li>Expert Faculty</li>
                <li>Job Placement Assistance</li>
                <li>Practical Learning Environment</li>
                <li>Personalized Career Support</li>
                <li>Global Internship Opportunities</li>
                <li>Flexible Learning Options</li>
                <li>Comprehensive Course Offerings</li>
                <li>A Pathway to Global Careers</li>
            </ul>
            </Col>
            <Col sm={6} md={6} lg={4} style={{alignItems:'right'}} className="d-flex justify-content-center m-0 p-0">
            <EnquiryForm/>
            </Col>
        </Row>
    </Container>

  )
}

export default WhyChoose